import {ContentDefinition, ReviewDefinition, SlidePanelDefinition, SmartLinkDefinition} from './definitions-base';
import {
    Allergy,
    ClinicalResult,
    CriticalVital,
    Immunization,
    LabReport,
    LabTest,
    Order,
    PatientEvent,
    Procedure,
    Task,
    TreatmentEntry,
    ImagingResult,
    ProgressNote,
} from '../models/models';
import {ICDCode} from '../models/ICD-code';
import {ICDCodeGroupService} from '../@core/icd-groups.service';
import {limitText} from '../utils/string.utils';
import {Visit} from '../models/visit';
import {Diagnosis} from '../models/diagnosis';
import {Hl7Message} from '../models/hl7-message';
import {Human} from '../models/human';
import {Patient} from '../models/patient';
import {getId} from '../utils/type.utils';
import {MenuItemDefinition} from '../portal/sidebar/menu-item';
import {SnomedConcept} from '../models/snomed-concept';

export class ContentDefinitions {
    static readonly ICD_CODE = new ContentDefinition({
        key: 'ICD_CODE',
        name: {lowerCase: 'ICD-10 code', capitalized: 'ICD-10 Code'},
        icon: 'tag',
        loadComponent: () => import('../portal/@slide-panel-preview/icd-code-preview/icd-code-preview.component').then(m => m.IcdCodePreviewComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/icd-code-preview/icd-code-preview.module').then(m => m.IcdCodePreviewModule),
        modelBase: ICDCode,
        actionEntryKey: 'icd',
    });
    static readonly SNOMED_CONCEPT = new ContentDefinition({
        key: 'SNOMED_CONCEPT',
        name: {lowerCase: 'snomed concept', capitalized: 'SNOMED Concept'},
        icon: 'tag',
        modelBase: SnomedConcept,
    });
    static readonly PATIENT = new ContentDefinition({
        key: 'PATIENT',
        path: 'patients/:id',
        icon: 'address-card',
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/patient-details.component').then(m => m.PatientDetailsComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/patient-details.module').then(m => m.PatientDetailsModule),
    });
    static readonly LAB_REPORT = new ContentDefinition<LabReport>({
        key: 'LAB_REPORT',
        name: {lowerCase: 'lab report', capitalized: 'Lab Report'},
        path: 'care-surveillance/lab-reports/:id',
        icon: 'vials',
        loadComponent: () => import('../portal/@slide-panel-preview/lab-report/lab-report.component').then(m => m.LabReportComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/lab-report/lab-report.module').then(m => m.LabReportModule),
        modelBase: LabReport,
        actionEntryKey: 'lab_test',
    });
    static readonly LAB_TEST = new ContentDefinition<LabTest>({
        key: 'LAB_TEST',
        path: 'care-surveillance/lab-reports/test/:id',
        icon: 'vials',
        loadComponent: () => import('../portal/@slide-panel-preview/lab-test/lab-test.component').then(m => m.LabTestComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/lab-test/lab-test.module').then(m => m.LabTestModule),
        modelBase: LabTest,
        actionEntryKey: 'lab_test',
        patientDetailsSubPath: '?tab=results&subTab=labs',
    });
    static readonly IMAGING_RESULT = new ContentDefinition({
        key: 'IMAGING_RESULT',
        modelBase: ImagingResult,
        actionEntryKey: 'imaging_result',
        patientDetailsSubPath: '?tab=results&subTab=imaging',
    });
    static readonly CLINICAL_RESULT = new ContentDefinition({
        key: 'CLINICAL_RESULT',
        modelBase: ClinicalResult,
        actionEntryKey: 'clinical_test_result',
        patientDetailsSubPath: '?tab=results&subTab=clinical_results',
    });
    static readonly EVENT = new ContentDefinition<PatientEvent>({
        key: 'EVENT',
        name: {lowerCase: 'fall', capitalized: 'Fall'},
        path: 'patients/event/:id',
        icon: 'star-shooting',
        loadComponent: () => import('../portal/@slide-panel-preview/event-preview/event-preview.component').then(m => m.EventPreviewComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/event-preview/event-preview.module').then(m => m.EventPreviewModule),
        modelBase: PatientEvent,
        actionEntryKey: 'event',
        patientDetailsSubPath: '?tab=events',
    });
    static readonly VITAL = new ContentDefinition<CriticalVital>({
        key: 'VITAL',
        name: {lowerCase: 'critical vital', capitalized: 'Critical Vital'},
        path: 'patients/vital/:id',
        icon: 'heartbeat',
        loadComponent: () => import('../portal/@slide-panel-preview/vital-preview/vital-preview.component').then(m => m.VitalPreviewComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/vital-preview/vital-preview.module').then(m => m.VitalPreviewModule),
        modelBase: CriticalVital,
        actionEntryKey: 'vital',
    });
    static readonly CRITICAL_VITALS = new ContentDefinition<CriticalVital>({
        key: 'CRITICAL_VITALS',
        name: {lowerCase: 'critical vitals', capitalized: 'Critical Vitals'},
        icon: 'heartbeat',
        // this is commented out because there is problem with the chart's tooltip if it's lazy loaded: https://github.com/swimlane/ngx-charts/issues/1733
        // loadComponent: () => import('../portal/@slide-panel-preview/critical-vitals-preview/critical-vitals-preview.component').then(m => m.CriticalVitalsPreviewComponent),
        // loadChildren: () => import('../portal/@slide-panel-preview/critical-vitals-preview/critical-vitals-preview.module').then(m => m.CriticalVitalsPreviewModule),
        modelBase: CriticalVital,
    });
    static readonly MEDICATION = new ContentDefinition<TreatmentEntry>({
        key: 'MEDICATION',
        path: 'care-surveillance/medications/:id',
        icon: 'prescription-bottle',
        loadComponent: () => import('../portal/@slide-panel-preview/medication-preview/medication-preview.component').then(m => m.MedicationPreviewComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/medication-preview/medication-preview.module').then(m => m.MedicationPreviewModule),
        modelBase: TreatmentEntry,
        actionEntryKey: 'treatment_history',
        patientDetailsSubPath: '?tab=medications',
        permissions: ['PERMISSION_READ_MEDICATION_LIST'],
    });
    static readonly DIAGNOSIS = new ContentDefinition<Diagnosis>({
        key: 'DIAGNOSIS',
        icon: 'stethoscope',
        modelBase: Diagnosis,
        actionEntryKey: 'diagnosis',
        patientDetailsSubPath: '?tab=diagnoses',
    });
    static readonly PATIENT_ICD = new ContentDefinition({
        key: 'PATIENT_ICD',
        path: 'patients/:patientId/icd/:id',
        icon: 'stethoscope',
        permissions: ['PERMISSION_READ_MEDICATION_LIST'],
    });
    static readonly PATIENT_SNOMED = new ContentDefinition({
        key: 'PATIENT_SNOMED',
        path: 'patients/:patientId/snomed/:id',
        icon: 'stethoscope',
        permissions: ['PERMISSION_READ_MEDICATION_LIST'],
    });
    static readonly OTHER_ORDER = new ContentDefinition({
        key: 'OTHER_ORDER',
        name: {lowerCase: 'EMR order', capitalized: 'EMR Order'},
        icon: 'clipboard-list-check',
        modelBase: Order,
        actionEntryKey: 'order',
        patientDetailsSubPath: '?tab=orders&subTab=emr-orders',
    });
    static readonly ALLERGY = new ContentDefinition({
        key: 'ALLERGY',
        modelBase: Allergy,
        patientDetailsSubPath: '/allergies',
        actionEntryKey: 'patient_allergy',
    });
    static readonly IMMUNIZATION = new ContentDefinition({
        key: 'IMMUNIZATION',
        modelBase: Immunization,
        patientDetailsSubPath: '/immunizations',
        actionEntryKey: 'immunization',
    });
    static readonly PROCEDURE = new ContentDefinition({
        key: 'PROCEDURE',
        modelBase: Procedure,
        patientDetailsSubPath: '/procedures',
        actionEntryKey: 'procedure',
    });
    static readonly TASK = new ContentDefinition({
        key: 'TASK',
        name: {lowerCase: 'note', capitalized: 'Note'},
        path: 'arsana-notes/:id',
        icon: 'comments',
        loadComponent: () => import('../portal/@slide-panel-preview/ccm-note/ccm-note.component').then(m => m.CcmNoteComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/ccm-note/ccm-note.module').then(m => m.CcmNoteModule),
        modelBase: Task,
    });
    static readonly TASK_PROPOSAL = new ContentDefinition({
        key: 'TASK_PROPOSAL',
        name: {lowerCase: 'visit proposal', capitalized: 'Visit Proposal'},
        icon: 'stethoscope',
        path: 'task-proposal/:id',
        loadComponent: () => import('../portal/@slide-panel-preview/task-proposal-preview/task-proposal-preview.component').then(m => m.TaskProposalPreviewComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/task-proposal-preview/task-proposal-preview.module').then(m => m.TaskProposalPreviewModule),
    });
    static readonly VISIT = new ContentDefinition({
        key: 'VISIT',
        name: {lowerCase: 'visit occasion', capitalized: 'Visit Occasion'},
        path: 'scheduler/visits/:id',
        icon: 'clipboard-list-check',
        modelBase: Visit,
    });
    static readonly LAB_SHIPMENT = new ContentDefinition({
        key: 'LAB_SHIPMENT',
        name: {lowerCase: 'testing order', capitalized: 'Testing Order'},
        icon: 'dolly',
        path: 'covid-testing/shipments/:id',
        loadComponent: () => import('../portal/@slide-panel-preview/lab-shipment-panel/lab-shipment-panel.component').then(m => m.LabShipmentPanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/lab-shipment-panel/lab-shipment-panel.module').then(m => m.LabShipmentPanelModule),
    });
    static readonly LAB_SHIPMENT_BOX = new ContentDefinition({
        key: 'LAB_SHIPMENT_BOX',
        name: {lowerCase: 'box', capitalized: 'Box'},
        icon: 'box-alt',
        path: 'covid-testing/shipments/boxes/:id',
        loadComponent: () => import('../portal/@slide-panel-preview/lab-shipment-box-panel/lab-shipment-box-panel.component').then(m => m.LabShipmentBoxPanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/lab-shipment-box-panel/lab-shipment-box-panel.module').then(m => m.LabShipmentBoxPanelModule),
    });
    static readonly LAB_SHIPMENT_BAG = new ContentDefinition({
        key: 'LAB_SHIPMENT_BAG',
        name: {lowerCase: 'bag', capitalized: 'Bag'},
        icon: 'dolly',
        path: 'covid-testing/shipments/bags/:id',
        loadComponent: () => import('../portal/@slide-panel-preview/lab-shipment-bag-panel/lab-shipment-bag-panel.component').then(m => m.LabShipmentBagPanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/lab-shipment-bag-panel/lab-shipment-bag-panel.module').then(m => m.LabShipmentBagPanelModule),
    });
    static readonly LAB_SHIPMENT_ORDER = new ContentDefinition({
        key: 'LAB_SHIPMENT_ORDER',
        name: {lowerCase: 'lab order', capitalized: 'Lab Order'},
        icon: 'vial',
        path: 'patients/lab-order/:id',
        loadComponent: () => import('../portal/@slide-panel-preview/lab-shipment-order-panel/lab-shipment-order-panel.component').then(m => m.LabShipmentOrderPanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/lab-shipment-order-panel/lab-shipment-order-panel.module').then(m => m.LabShipmentOrderPanelModule),
    });
    static readonly ADD_PATIENT_TO_BST_DIALOG = new ContentDefinition({
        key: 'ADD_PATIENT_TO_BST_DIALOG',
        name: {lowerCase: 'add Patient to BroadStreet', capitalized: 'Add Patient to BroadStreet'},
        icon: 'check-circle',
        loadComponent: () => import('../portal/@portal-shared/add-patient-to-bst-dialog/add-patient-to-bst-dialog.component').then(m => m.AddPatientToBstDialogComponent),
    });
    static readonly PERSON_EDIT = new ContentDefinition({
        key: 'PERSON_EDIT',
        name: {lowerCase: 'person edit', capitalized: 'Person Edit'},
        icon: 'check',
        path: 'admin/patient-identification/:patientId/person/:id',
    });
    static readonly PERSON_MERGE = new ContentDefinition({
        key: 'PERSON_MERGE',
        name: {lowerCase: 'person merge', capitalized: 'Person Merge'},
        permissions: ['PERMISSION_WRITE_PERSONS'],
    });
    static readonly IMPORT_ACCOUNT = new ContentDefinition({
        key: 'IMPORT_ACCOUNT',
        path: 'admin/import-accounts/:id',
        loadComponent: () => import('../portal/@slide-panel-preview/import-account-edit/import-account-edit.component').then(m => m.ImportAccountEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/import-account-edit/import-account-edit.module').then(m => m.ImportAccountEditModule),
    });
    static readonly IMPORT_ACCOUNT_NEW = new ContentDefinition({
        ...this.IMPORT_ACCOUNT,
        key: 'IMPORT_ACCOUNT_NEW',
        path: 'admin/import-accounts/new',
    });
    static readonly IMPORT_MANAGER = new ContentDefinition({
        key: 'IMPORT_MANAGER',
        name: {lowerCase: 'import manager', capitalized: 'Import Manager'},
        loadComponent: () => import('../portal/admin/import-manager/import-manager.component').then(m => m.ImportManagerComponent),
        loadChildren: () => import('../portal/admin/import-manager/import-manager.module').then(m => m.ImportManagerModule),
    });
    static readonly CALENDAR_EVENT = new ContentDefinition({
        key: 'CALENDAR_EVENT',
        name: {lowerCase: 'calendar event', capitalized: 'calendar event'},
        icon: 'calendar-times',
        loadComponent: () => import('../portal/@slide-panel-preview/calendar-event-edit/calendar-event-edit.component').then(m => m.CalendarEventEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/calendar-event-edit/calendar-event-edit.module').then(m => m.CalendarEventEditModule),
    });
    static readonly HL7_MESSAGE = new ContentDefinition({
        key: 'HL7_MESSAGE',
        name: {lowerCase: 'hl7 message', capitalized: 'HL7 Message'},
        path: 'system-maintenance/hl7/:id',
        modelBase: Hl7Message,
        loadComponent: () => import('../portal/@slide-panel-preview/hl7-message-details/hl7-message-details.component').then(m => m.Hl7MessageDetailsComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/hl7-message-details/hl7-message-details.module').then(m => m.Hl7MessageDetailsModule),
    });
    static readonly HL7_MESSAGES = new ContentDefinition({
        key: 'HL7_MESSAGES',
        name: {lowerCase: 'hl7 messages', capitalized: 'HL7 Messages'},
        loadComponent: () => import('../portal/system-maintenance/hl7-messages-container/hl7-messages/hl7-messages.component').then(m => m.Hl7MessagesComponent),
        loadChildren: () => import('../portal/system-maintenance/hl7-messages-container/hl7-messages/hl7-messages.module').then(m => m.Hl7MessagesModule),
    });
    static readonly SYNC_DETAIL = new ContentDefinition({
        key: 'SYNC_DETAIL',
        path: 'system-maintenance/import-errors/:syncProcessId',
        loadComponent: () => import('../portal/@slide-panel-preview/sync-detail/sync-detail.component').then(m => m.SyncDetailComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/sync-detail/sync-detail.module').then(m => m.SyncDetailModule),
    });
    static readonly SYNC_DETAIL_BY_FACILITY = new ContentDefinition({
        ...this.SYNC_DETAIL,
        path: 'system-maintenance/import-errors/:syncProcessId/:facilityId',
        pathStringParams: ['syncProcessId', 'facilityId'],
    });
    static readonly SYNC_PROCESS = new ContentDefinition({
        key: 'SYNC_PROCESS',
        path: 'system-maintenance/import-errors/syncProcess/:syncDetailId/:id',
        loadComponent: () => import('../portal/@slide-panel-preview/sync-process/sync-process.component').then(m => m.SyncProcessComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/sync-process/sync-process.module').then(m => m.SyncProcessModule),
    });
    static readonly SYNC_DETAIL_TASK = new ContentDefinition({
        key: 'SYNC_DETAIL_TASK',
        path: 'system-maintenance/import-errors/:syncDetailId/:syncProcessId/:id',
        pathStringParams: ['id'],
        loadComponent: () => import('../portal/@slide-panel-preview/sync-task/sync-task.component').then(m => m.SyncTaskComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/sync-task/sync-task.module').then(m => m.SyncTaskModule),
    });
    static readonly USER_EDIT = new ContentDefinition({
        key: 'USER_EDIT',
        name: {lowerCase: 'user edit', capitalized: 'User Edit'},
        icon: 'user',
        path: 'admin/users/:id/edit',
    });
    static readonly USER_EDIT_NEW = new ContentDefinition({
        ...this.USER_EDIT,
        key: 'USER_EDIT_NEW',
        path: 'admin/users/new',
    });
    static readonly MEDICATION_INTERACTION = new ContentDefinition({
        key: 'MEDICATION_INTERACTION',
        name: {lowerCase: 'medication interaction', capitalized: 'Medication Interaction'},
    });
    static readonly EMR_UPLOAD_DETAIL = new ContentDefinition({
        key: 'EMR_UPLOAD_DETAIL',
        path: 'system-maintenance/emr-uploads/:id',
        loadComponent: () => import('../portal/@slide-panel-preview/emr-upload-details/emr-upload-details.component').then(m => m.EmrUploadDetailsComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/emr-upload-details/emr-upload-details.module').then(m => m.EmrUploadDetailsModule),
    });
    static readonly EMR_UPLOADS = new ContentDefinition({
        key: 'EMR_UPLOADS',
        loadComponent: () => import('../portal/system-maintenance/emr-uploads/emr-uploads.component').then(m => m.EmrUploadsComponent),
        loadChildren: () => import('../portal/system-maintenance/emr-uploads/emr-uploads.module').then(m => m.EmrUploadsModule),
    });
    static readonly CDS_INTERVENTION = new ContentDefinition({
        key: 'CDS_INTERVENTION',
        path: 'admin/cds-interventions/:id',
        icon: 'comment-exclamation',
        loadComponent: () => import('../portal/admin/cds-interventions/cds-intervention/cds-intervention-details/cds-intervention-details.component').then(m => m.CdsInterventionDetailsComponent),
        loadChildren: () => import('../portal/admin/cds-interventions/cds-intervention/cds-intervention-details/cds-intervention-details.module').then(m => m.CdsInterventionDetailsModule),
    });
    static readonly PATIENT_DOCUMENT = new ContentDefinition({
        key: 'PATIENT_DOCUMENT',
        icon: 'file',
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/patient-document-uploader/patient-document-uploader.component').then(m => m.PatientDocumentUploaderComponent),
    });
    static readonly ORGANIZATION_EDIT = new ContentDefinition({
        key: 'ORGANIZATION_EDIT',
        name: {lowerCase: 'organization edit', capitalized: 'Organization Edit'},
        icon: 'building',
        path: 'admin/organizations/:id',
        loadComponent: () => import('../portal/admin/organizations/organization-edit/organization-edit.component').then(m => m.OrganizationEditComponent),
        loadChildren: () => import('../portal/admin/organizations/organization-edit/organization-edit.module').then(m => m.OrganizationEditModule),
        permissions: ['PERMISSION_WRITE_ORGANIZATION'],
    });
    static readonly FACILITY_EDIT = new ContentDefinition({
        key: 'FACILITY_EDIT',
        name: {lowerCase: 'facility edit', capitalized: 'Facility Edit'},
        icon: 'building',
        path: 'admin/organizations/facilities/:id',
        loadComponent: () => import('../portal/admin/organizations/facility-edit/facility-edit.component').then(m => m.FacilityEditComponent),
        loadChildren: () => import('../portal/admin/organizations/facility-edit/facility-edit.module').then(m => m.FacilityEditModule),
        permissions: ['PERMISSION_WRITE_FACILITY'],
    });
    static readonly LABEL_EDIT = new ContentDefinition({
        key: 'LABEL_EDIT',
        path: 'admin/label/:id',
        loadComponent: () => import('../portal/admin/labels-manager/label-edit/label-edit.component').then(m => m.LabelEditComponent),
        loadChildren: () => import('../portal/admin/labels-manager/label-edit/label-edit.module').then(m => m.LabelEditModule),
        permissions: ['PERMISSION_WRITE_LABELS'],
    });
    static readonly LABEL_EDIT_NEW = new ContentDefinition({
        ...this.LABEL_EDIT,
        key: 'LABEL_EDIT_NEW',
        path: 'admin/label/new',
    });
    static readonly CLINICAL_NOTE = new ContentDefinition({
        key: 'CLINICAL_NOTE',
        path: 'clinical-notes/:id',
        loadChildren: () => import('../portal/@slide-panel-preview/clinical-note/clinical-notes.module').then(m => m.ClinicalNotesModule),
        loadComponent: () => import('../portal/@slide-panel-preview/clinical-note/clinical-note.component').then(m => m.ClinicalNoteComponent),
    });
}

export class SlidePanelDefinitions {
    static readonly PATIENT = new SlidePanelDefinition(ContentDefinitions.PATIENT, {
        size: 'lg',
        verticalAlign: 'top',
        showFab: true,
    });
    static readonly LAB_REPORT = new SlidePanelDefinition(ContentDefinitions.LAB_REPORT, {
        size: 'lg',
        showFab: true,
    });
    static readonly LAB_REPORT_HISTORY = new SlidePanelDefinition({
        key: 'LAB_REPORT_HISTORY',
        path: 'care-surveillance/lab-reports/:id/history',
        loadComponent: () => import('../portal/@slide-panel-preview/lab-report/lab-report-history/lab-report-history.component').then(m => m.LabReportHistoryComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/lab-report/lab-report-history/lab-report-history.module').then(m => m.LabReportHistoryModule),
    });
    static readonly LAB_TEST = new SlidePanelDefinition(ContentDefinitions.LAB_TEST, {
        defRootUrl: 'care-surveillance/lab-reports',
        size: 'lg',
        showFab: true,
    });
    static readonly IMAGING_RESULT_EDIT = new SlidePanelDefinition({
        key: 'IMAGING_RESULT_EDIT',
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@portal-shared/imaging/imaging-results-edit/imaging-results-edit-panel/imaging-results-edit-panel.component').then(m => m.ImagingResultsEditPanelComponent),
        loadChildren: () => import('../portal/@portal-shared/imaging/imaging-results-edit/imaging-results-edit-panel/imaging-results-edit-panel.module').then(m => m.ImagingResultsEditPanelModule),
    });
    static readonly CLINICAL_RESULT_EDIT = new SlidePanelDefinition({
        key: 'CLINICAL_RESULT_EDIT',
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@portal-shared/clinical-results/clinical-results-edit/clinical-results-edit-panel/clinical-results-edit-panel.component').then(m => m.ClinicalResultsEditPanelComponent),
        loadChildren: () => import('../portal/@portal-shared/clinical-results/clinical-results-edit/clinical-results-edit-panel/clinical-results-edit-panel.module').then(m => m.ClinicalResultsEditPanelModule),
    });
    static readonly EVENT = new SlidePanelDefinition(ContentDefinitions.EVENT, {
        defRootUrl: 'patients',
        showFab: true,
    });
    static readonly VITAL = new SlidePanelDefinition(ContentDefinitions.VITAL, {
        defRootUrl: 'patients',
        showFab: true,
    });
    static readonly CRITICAL_VITALS = new SlidePanelDefinition(ContentDefinitions.CRITICAL_VITALS, {
        showFab: true,
    });
    static readonly MEDICATION = new SlidePanelDefinition(ContentDefinitions.MEDICATION, {
        showFab: true,
    });
    static readonly PATIENT_DRUG_CATEGORY = new SlidePanelDefinition({
        key: 'PATIENT_ICD_GROUP',
        path: 'patients/:patientId/drug-category/:id',
        icon: 'prescription-bottle',
        permissions: ['PERMISSION_READ_MEDICATION_LIST'],
        defRootUrl: 'patients',
        size: 'lg',
        showFab: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-drug-category/patient-drug-category.component').then(m => m.PatientDrugCategoryComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-drug-category/patient-drug-category.module').then(m => m.PatientDrugCategoryModule),
    });
    static readonly PATIENT_ICD = new SlidePanelDefinition(ContentDefinitions.PATIENT_ICD, {
        defRootUrl: 'patients',
        size: 'lg',
        showFab: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-icd/patient-icd.component').then(m => m.PatientIcdComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-icd/patient-icd.module').then(m => m.PatientIcdModule),
    });
    static readonly PATIENT_SNOMED = new SlidePanelDefinition(ContentDefinitions.PATIENT_SNOMED, {
        defRootUrl: 'patients',
        size: 'lg',
        showFab: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-snomed/patient-snomed.component').then(m => m.PatientSnomedComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-snomed/patient-snomed.module').then(m => m.PatientSnomedModule),
    });
    static readonly PATIENT_ICD_GROUP = new SlidePanelDefinition({
        key: 'PATIENT_ICD_GROUP',
        path: 'patients/:patientId/icd-category/:id',
        icon: 'stethoscope',
        permissions: ['PERMISSION_READ_MEDICATION_LIST'],
        defRootUrl: 'patients',
        size: 'lg',
        showFab: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-icd-group/patient-icd-group.component').then(m => m.PatientIcdGroupComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-icd-group/patient-icd-group.module').then(m => m.PatientIcdGroupModule),
    });
    static readonly IMMUNIZATION_LIST = new SlidePanelDefinition({
        key: 'IMMUNIZATION_LIST',
        path: 'patients/:id/immunizations',
        verticalAlign: 'top',
        customMarkup: true,
        showFab: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/immunizations/immunization-list/immunization-list-panel/immunization-list-panel.component').then(m => m.ImmunizationListPanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/immunizations/immunization-list/immunization-list-panel/immunization-list-panel.module').then(m => m.ImmunizationListPanelModule),
    });
    static readonly IMMUNIZATION_EDIT = new SlidePanelDefinition({
        key: 'IMMUNIZATION_EDIT',
        permissions: ['PERMISSION_WRITE_PATIENT_CLINICAL'],
        verticalAlign: 'top',
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/immunizations/immunization-edit/immunization-edit.component').then(m => m.ImmunizationEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/immunizations/immunization-edit/immunization-edit.module').then(m => m.ImmunizationEditModule),
    });
    static readonly PROCEDURE_LIST = new SlidePanelDefinition({
        key: 'PROCEDURE_LIST',
        path: 'patients/:id/procedures',
        verticalAlign: 'top',
        customMarkup: true,
        showFab: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/procedures/procedure-list/procedure-list-panel/procedure-list-panel.component').then(m => m.ProcedureListPanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/procedures/procedure-list/procedure-list-panel/procedure-list-panel.module').then(m => m.ProcedureListPanelModule),
    });
    static readonly PROCEDURE_EDIT = new SlidePanelDefinition({
        key: 'PROCEDURE_EDIT',
        permissions: ['PERMISSION_WRITE_PATIENT_CLINICAL'],
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/procedures/procedure-edit/procedure-edit.component').then(m => m.ProcedureEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/procedures/procedure-edit/procedure-edit.module').then(m => m.ProcedureEditModule),
    });
    static readonly ALLERGY_LIST = new SlidePanelDefinition({
        key: 'ALLERGY_LIST',
        path: 'patients/:id/allergies',
        verticalAlign: 'top',
        customMarkup: true,
        showFab: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/allergies/allergy-list/allergy-list-panel/allergy-list-panel.component').then(m => m.AllergyListPanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/allergies/allergy-list/allergy-list-panel/allergy-list-panel.module').then(m => m.AllergyListPanelModule),
    });
    static readonly ALLERGY_EDIT = new SlidePanelDefinition({
        key: 'ALLERGY_EDIT',
        permissions: ['PERMISSION_WRITE_PATIENT_CLINICAL'],
        verticalAlign: 'top',
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/allergies/allergy-edit/allergy-edit.component').then(m => m.AllergyEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/allergies/allergy-edit/allergy-edit.module').then(m => m.AllergyEditModule),
    });
    static readonly TASK = new SlidePanelDefinition(ContentDefinitions.TASK);
    static readonly TASK_HISTORY = new SlidePanelDefinition({
        key: 'TASK_HISTORY',
        path: 'arsana-notes/:id/history',
        loadComponent: () => import('../portal/@slide-panel-preview/ccm-note/note-editing-history/note-editing-history.component').then(m => m.NoteEditingHistoryComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/ccm-note/note-editing-history/note-editing-history.module').then(m => m.NoteEditingHistoryModule),
    });
    static readonly TASK_PROPOSAL = new SlidePanelDefinition(ContentDefinitions.TASK_PROPOSAL, {
        defRootUrl: 'patients',
    });
    static readonly TASK_PROPOSAL_EDIT = new SlidePanelDefinition({
        key: 'TASK_PROPOSAL_EDIT',
        name: this.TASK_PROPOSAL.name,
        icon: this.TASK_PROPOSAL.icon,
        path: 'task-proposal/:id/edit',
        defRootUrl: 'patients',
        loadComponent: () => import('../portal/@slide-panel-preview/task-proposal-edit/task-proposal-edit.component').then(m => m.TaskProposalEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/task-proposal-edit/task-proposal-edit.module').then(m => m.TaskProposalEditModule),
    });
    static readonly TASK_PROPOSAL_NEW = new SlidePanelDefinition({
        key: 'TASK_PROPOSAL_NEW',
        name: this.TASK_PROPOSAL_EDIT.name,
        icon: this.TASK_PROPOSAL_EDIT.icon,
        path: 'task-proposal/new/:patient',
        loadComponent: this.TASK_PROPOSAL_EDIT.loadComponent,
        loadChildren: this.TASK_PROPOSAL_EDIT.loadChildren,
        defRootUrl: 'patients',
    });
    static readonly VISIT_EDIT = new SlidePanelDefinition({
        key: 'VISIT_EDIT',
        name: {lowerCase: 'visit occasion', capitalized: 'Visit Occasion'},
        icon: 'clipboard-list-check',
        size: 'lg',
        loadComponent: () => import('../portal/@slide-panel-preview/visit-edit/visit-edit.component').then(m => m.VisitEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/visit-edit/visit-edit.module').then(m => m.VisitEditModule),
    });
    static readonly VISIT_COMPOSING_PATIENT_LIST = new SlidePanelDefinition({
        key: 'VISIT_COMPOSING_PATIENT_LIST',
        name: {lowerCase: 'patient list', capitalized: 'Patient List'},
        icon: 'clipboard-user',
        size: 'lg',
        loadComponent: () => import('../portal/@slide-panel-preview/visit-composing-patient-list/visit-composing-patient-list.component').then(m => m.VisitComposingPatientListComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/visit-composing-patient-list/visit-composing-patient-list.module').then(m => m.VisitComposingPatientListModule),
    });
    static readonly VISIT_COMPOSING_BY_OCCASIONS = new SlidePanelDefinition({
        key: 'VISIT_COMPOSING_BY_OCCASIONS',
        name: {lowerCase: 'patient list', capitalized: 'Patient List'},
        icon: 'clipboard-user',
        size: 'sm',
        loadComponent: () => import('../portal/visit/visit-composing-by-occasions/visit-composing-by-occasions.component').then(m => m.VisitComposingByOccasionsComponent),
        loadChildren: () => import('../portal/visit/visit-composing-by-occasions/visit-composing-by-occasions.module').then(m => m.VisitComposingByOccasionsModule),
    });
    static readonly PATIENT_VITAL_HISTORY = new SlidePanelDefinition({
        key: 'PATIENT_VITAL_HISTORY',
        name: {lowerCase: 'vital history', capitalized: 'Vital History'},
        icon: 'heartbeat',
        defRootUrl: 'patients',
        size: 'lg',
        loadComponent: () => import('../portal/@slide-panel-preview/patient-vitals-history/patient-vitals-history.component').then(m => m.PatientVitalsHistoryComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-vitals-history/patient-vitals-history.module').then(m => m.PatientVitalsHistoryModule),
    });
    static readonly LAB_SHIPMENT_CREATE = new SlidePanelDefinition({
        key: 'LAB_SHIPMENT_CREATE',
        name: {lowerCase: 'new testing order', capitalized: 'New Testing Order'},
        icon: 'dolly',
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/lab-shipment-create-panel/lab-shipment-create-panel.component').then(m => m.LabShipmentCreatePanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/lab-shipment-create-panel/lab-shipment-create-panel.module').then(m => m.LabShipmentCreatePanelModule),
    });
    static readonly COVID_SHIPMENT_CREATE = new SlidePanelDefinition({
        key: 'COVID_SHIPMENT_CREATE',
        name: {lowerCase: 'new COVID testing order', capitalized: 'New COVID Testing Order'},
        icon: 'dolly',
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/covid-shipment-create-panel/covid-shipment-create-panel.component').then(m => m.CovidShipmentCreatePanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/covid-shipment-create-panel/covid-shipment-create-panel.module').then(m => m.CovidShipmentCreatePanelModule),
    });
    static readonly LAB_SHIPMENT = new SlidePanelDefinition(ContentDefinitions.LAB_SHIPMENT, {
        verticalAlign: 'top',
        customMarkup: true,
    });
    static readonly LAB_SHIPMENT_BOX = new SlidePanelDefinition(ContentDefinitions.LAB_SHIPMENT_BOX, {
        defRootUrl: 'covid-testing/shipments',
        verticalAlign: 'top',
        customMarkup: true,
    });
    static readonly LAB_SHIPMENT_BAG = new SlidePanelDefinition(ContentDefinitions.LAB_SHIPMENT_BAG, {
        defRootUrl: 'covid-testing/shipments',
        verticalAlign: 'top',
        customMarkup: true,
    });
    static readonly LAB_SHIPMENT_ORDER = new SlidePanelDefinition(ContentDefinitions.LAB_SHIPMENT_ORDER, {
        defRootUrl: 'covid-testing/shipments',
        customMarkup: true,
    });
    static readonly PERSON_EDIT = new SlidePanelDefinition(ContentDefinitions.PERSON_EDIT, {
        verticalAlign: 'top',
        defRootUrl: 'patients',
        loadComponent: () => import('../portal/@slide-panel-preview/person-edit/person-edit.component').then(m => m.PersonEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/person-edit/person-edit.module').then(m => m.PersonEditModule),
    });
    static readonly PATIENT_MERGE = new SlidePanelDefinition({
        key: 'PATIENT_MERGE',
        name: {lowerCase: 'patient merge', capitalized: 'Patient Merge'},
        icon: 'pen-alt',
        path: 'patients/merge/:id',
        defRootUrl: 'patients',
        size: 'lg',
        loadComponent: () => import('../portal/@slide-panel-preview/patient-merge/patient-merge.component').then(m => m.PatientMergeComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-merge/patient-merge.module').then(m => m.PatientMerge),
    });
    static readonly PATIENT_CMRN_LIST = new SlidePanelDefinition({
        key: 'PATIENT_CMRN_LIST',
        name: {lowerCase: 'patient cmrn list', capitalized: 'Patients\' CMRN list'},
        icon: 'pen-alt',
        size: 'lg',
        verticalAlign: 'top',
        loadComponent: () => import('../portal/@slide-panel-preview/patient-cmrn-list/patient-cmrn-list.component').then(m => m.PatientCmrnListComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-cmrn-list/patient-cmrn-list.module').then(m => m.PatientCmrnListModule),
    });
    static readonly PATIENT_EDIT_NEW = new SlidePanelDefinition({
        key: 'PATIENT_EDIT_NEW',
        name: {lowerCase: 'new patient', capitalized: 'New Patient'},
        icon: 'address-card',
        path: 'admin/patients/new',
        permissions: ['PERMISSION_WRITE_PATIENTS'],
        defRootUrl: 'patients',
        loadComponent: () => import('../portal/@slide-panel-preview/patient-edit/patient-edit.component').then(m => m.PatientEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-edit/patient-edit.module').then(m => m.PatientEditModule),
    });
    static readonly IMPORT_ACCOUNT = new SlidePanelDefinition(ContentDefinitions.IMPORT_ACCOUNT, {
        size: 'md',
    });
    static readonly IMPORT_ACCOUNT_NEW = new SlidePanelDefinition(ContentDefinitions.IMPORT_ACCOUNT_NEW, {
        size: 'md',
    });
    static readonly IMPORT_MANAGER = new SlidePanelDefinition(ContentDefinitions.IMPORT_MANAGER, {
        verticalAlign: 'top',
        size: 'lg',
    });
    static readonly CALENDAR_EVENT = new SlidePanelDefinition(ContentDefinitions.CALENDAR_EVENT, {
        size: 'sm',
    });
    static readonly HL7_MESSAGE = new SlidePanelDefinition(ContentDefinitions.HL7_MESSAGE, {
        size: 'lg',
    });
    static readonly HL7_MESSAGES = new SlidePanelDefinition(ContentDefinitions.HL7_MESSAGES, {
        size: 'lg',
        customMarkup: true,
    });
    static readonly ORGANISM = new SlidePanelDefinition({
        key: 'ORGANISM',
        icon: 'bacteria',
        path: 'care-surveillance/culture-specimen-dashboard/:id',
        loadComponent: () => import('../portal/@slide-panel-preview/organism-panel/organism-panel.component').then(m => m.OrganismPanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/organism-panel/organism-panel.module').then(m => m.OrganismPanelModule),
    });
    static readonly SYNC_DETAIL = new SlidePanelDefinition(ContentDefinitions.SYNC_DETAIL, {
        verticalAlign: 'top',
        defRootUrl: 'system-maintenance/import-errors?tab=syncProcesses',
    });
    static readonly SYNC_DETAIL_BY_FACILITY = new SlidePanelDefinition(ContentDefinitions.SYNC_DETAIL_BY_FACILITY, {
        verticalAlign: 'top',
    });
    static readonly SYNC_PROCESS = new SlidePanelDefinition(ContentDefinitions.SYNC_PROCESS, {
        verticalAlign: 'top',
    });
    static readonly SYNC_DETAIL_TASK = new SlidePanelDefinition(ContentDefinitions.SYNC_DETAIL_TASK, {
        verticalAlign: 'top',
    });
    static readonly EMBED = new SlidePanelDefinition(new ContentDefinition({key: 'EMBED'}), {
        customMarkup: true,
        loadComponent: () => import('../portal/@portal-theme/components/embed/embed.component').then(m => m.EmbedComponent),
    });
    static readonly USER_EDIT = new SlidePanelDefinition(ContentDefinitions.USER_EDIT, {
        defRootUrl: 'admin/users',
        size: 'lg',
        loadComponent: () => import('../portal/@slide-panel-preview/user-edit/user-edit.component').then(m => m.UserEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/user-edit/user-edit.module').then(m => m.UserEditModule),
    });
    static readonly USER_EDIT_NEW = new SlidePanelDefinition(ContentDefinitions.USER_EDIT_NEW, {
        defRootUrl: this.USER_EDIT.defRootUrl,
        size: this.USER_EDIT.size,
        loadComponent: this.USER_EDIT.loadComponent,
        loadChildren: this.USER_EDIT.loadChildren,
    });
    static readonly EMR_UPLOAD_DETAIL = new SlidePanelDefinition(ContentDefinitions.EMR_UPLOAD_DETAIL, {
        size: 'lg',
        customMarkup: true,
    });
    static readonly EMR_UPLOADS = new SlidePanelDefinition(ContentDefinitions.EMR_UPLOADS, {
        size: 'lg',
        customMarkup: true,
    });
    static readonly PATIENT_ACCESS_SUMMARY = new SlidePanelDefinition({
        key: 'PATIENT_ACCESS_SUMMARY',
        path: 'admin/patient-access-management/patient/:id',
        defRootUrl: 'admin/patient-access-management',
        size: 'lg',
        verticalAlign: 'top',
        loadComponent: () => import('../portal/@slide-panel-preview/patient-access-summary/patient-access-summary.component').then(m => m.PatientAccessSummaryComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-access-summary/patient-access-summary.module').then(m => m.PatientAccessSummaryModule),
    });
    static readonly MEDICAL_PROGRAM_HISTORY = new SlidePanelDefinition({
        key: 'MEDICAL_PROGRAMS',
        loadComponent: () => import('../portal/@slide-panel-preview/medical-program-history/medical-program-history.component').then(m => m.MedicalProgramHistoryComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/medical-program-history/medical-program-history.module').then(m => m.MedicalProgramHistoryModule),
    });
    static readonly APP_CREATE = new SlidePanelDefinition({
        key: 'APP_CREATE',
        verticalAlign: 'top',
        loadComponent: () => import('../portal/admin/app-management/app-create/app-create.component').then(m => m.AppCreateComponent),
        loadChildren: () => import('../portal/admin/app-management/app-management.module').then(m => m.AppManagementModule),
    });
    static readonly PATIENT_ACCESS_HISTORY = new SlidePanelDefinition({
        key: 'PATIENT_ACCESS_HISTORY',
        verticalAlign: 'top',
        loadComponent: () => import('../portal/@slide-panel-preview/patient-access-history/patient-access-history.component').then(m => m.PatientAccessHistoryComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-access-history/patient-access-history.module').then(m => m.PatientAccessHistoryModule),
    });
    static readonly PATIENT_SPECIALTY_SUMMARY = new SlidePanelDefinition({
        key: 'PATIENT_SPECIALTY_SUMMARY',
        path: 'patients/:patientId/specialty/:specialtyId',
        defRootUrl: '/patients',
        size: 'lg',
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-specialty-summary-details/patient-specialty-summary-details.component').then(m => m.PatientSpecialtySummaryDetailsComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-specialty-summary-details/patient-specialty-summary-details.module').then(m => m.PatientSpecialtySummaryDetailsModule),
    });
    static readonly CENSUS_EDIT = new SlidePanelDefinition({
        key: 'CENSUS_EDIT',
        permissions: ['PERMISSION_WRITE_CENSUS_HISTORY', 'PERMISSION_WRITE_PATIENT_ADMIN', 'PERMISSION_WRITE_PATIENT_CLINICAL'],
        path: 'patients/:patientId/census-edit/:id',
        defRootUrl: '/patients',
        size: 'lg',
        verticalAlign: 'top',
        loadComponent: () => import('../portal/@portal-shared/census-edit/census-edit.component').then(m => m.CensusEditComponent),
        loadChildren: () => import('../portal/@portal-shared/census-edit/census-edit.module').then(m => m.CensusEditModule),
    });
    static readonly HOSPITAL_LEAVES_PANEL = new SlidePanelDefinition({
        key: 'HOSPITAL_LEAVES_PANEL',
        permissions: ['PERMISSION_WRITE_CENSUS_HISTORY', 'PERMISSION_WRITE_PATIENT_ADMIN', 'PERMISSION_WRITE_PATIENT_CLINICAL'],
        path: 'patients/:id/hospital-leaves',
        size: 'lg',
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/hospital-leaves/hospital-leaves-panel/hospital-leaves-panel.component').then(m => m.HospitalLeavesPanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/hospital-leaves/hospital-leaves-panel/hospital-leaves-panel.module').then(m => m.HospitalLeavesPanelModule),
    });
    static readonly CENSUS_EDIT_NEW = new SlidePanelDefinition(SlidePanelDefinitions.CENSUS_EDIT, {
        key: 'CENSUS_EDIT_NEW',
        path: 'patients/:patientId/census-edit/new',
        verticalAlign: 'top',
    });
    static readonly CENSUS_EDIT_HISTORY = new SlidePanelDefinition({
        key: 'CENSUS_EDIT_HISTORY',
        permissions: ['PERMISSION_WRITE_CENSUS_HISTORY'],
        verticalAlign: 'top',
        loadComponent: () => import('../portal/@portal-shared/census-edit-history/census-edit-history.component').then(m => m.CensusEditHistoryComponent),
        loadChildren: () => import('../portal/@portal-shared/census-edit-history/census-edit-history.module').then(m => m.CensusEditHistoryModule),
    });
    static readonly ORDERS_FILTERED_DOWNLOAD = new SlidePanelDefinition({
        permissions: ['PERMISSION_READ_ORDERS_REPORT'],
        key: 'ORDERS_FILTERED_DOWNLOAD',
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/order-report-download/order-report-download.component').then(m => m.OrderReportDownloadComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/order-report-download/order-report-download.module').then(m => m.OrderReportDownloadModule),
    });
    static readonly SYSTEM_ALERT_EDIT = new SlidePanelDefinition({
        key: 'SYSTEM_ALERT_EDIT',
        permissions: ['PERMISSION_WRITE_SYSTEM_ALERTS'],
        size: 'sm',
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/admin/system-alerts/system-alert-edit/system-alert-edit.component').then(m => m.SystemAlertEditComponent),
        loadChildren: () => import('../portal/admin/system-alerts/system-alert-edit/system-alert-edit.module').then(m => m.SystemAlertEditModule),
    });
    static readonly DEVICE_LIST = new SlidePanelDefinition({
        key: 'DEVICE_LIST',
        path: 'patients/:id/devices',
        size: 'lg',
        verticalAlign: 'top',
        customMarkup: true,
        showFab: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/devices/device-list/device-list-panel/device-list-panel.component').then(m => m.DeviceListPanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/devices/device-list/device-list-panel/device-list-panel.module').then(m => m.DeviceListPanelModule),
    });
    static readonly RELATED_PERSON_EDIT = new SlidePanelDefinition({
        key: 'RELATED_PERSON_EDIT',
        permissions: ['PERMISSION_WRITE_PATIENT_CLINICAL'],
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/related-persons/related-person-edit/related-person-edit.component').then(m => m.RelatedPersonEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/related-persons/related-person-edit/related-person-edit.module').then(m => m.RelatedPersonEditModule),
    });
    static readonly PERSON_MERGE = new SlidePanelDefinition({
        key: 'PERSON_MERGE',
        permissions: ['PERMISSION_WRITE_PERSONS'],
        size: 'lg',
        loadComponent: () => import('../portal/admin/person-management/person-merge/person-merge.component').then(m => m.PersonMergeComponent),
        loadChildren: () => import('../portal/admin/person-management/person-merge/person-merge.module').then(m => m.PersonMergeModule),
    });
    static readonly RELATED_PERSON_LIST = new SlidePanelDefinition({
        key: 'RELATED_PERSON_LIST',
        path: 'patients/:id/related-persons',
        verticalAlign: 'top',
        customMarkup: true,
        showFab: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/related-persons/related-person-list/related-person-list-panel/related-person-list-panel.component').then(m => m.RelatedPersonListPanelComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/related-persons/related-person-list/related-person-list-panel/related-person-list-panel.module').then(m => m.RelatedPersonListPanelModule),
    });
    static readonly PATIENT_EMAIL_EDIT = new SlidePanelDefinition({
        key: 'PATIENT_EMAIL_EDIT',
        // path: ??
        permissions: ['PERMISSION_WRITE_PATIENT_ADMIN'],
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-contact-info/patient-email/patient-email-edit/patient-email-edit.component').then(m => m.PatientEmailEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-contact-info/patient-email/patient-email-edit.module').then(m => m.PatientEmailEditModule),
    });
    static readonly PATIENT_PHONE_EDIT = new SlidePanelDefinition({
        key: 'PATIENT_PHONE_EDIT',
        // path: ??
        permissions: ['PERMISSION_WRITE_PATIENT_ADMIN'],
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-contact-info/patient-phone/patient-phone-edit/patient-phone-edit.component').then(m => m.PatientPhoneEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-contact-info/patient-phone/patient-phone-edit.module').then(m => m.PatientPhoneEditModule),
    });
    static readonly PATIENT_ADDRESS_EDIT = new SlidePanelDefinition({
        key: 'PATIENT_ADDRESS_EDIT',
        // path: ??
        permissions: ['PERMISSION_WRITE_PATIENT_ADMIN'],
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-contact-info/patient-address/patient-address-edit/patient-address-edit.component').then(m => m.PatientAddressEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-contact-info/patient-address/patient-address-edit.module').then(m => m.PatientAddressEditModule),
    });
    static readonly CDS_INTERVENTION = new SlidePanelDefinition(ContentDefinitions.CDS_INTERVENTION, {
        verticalAlign: 'top',
        showFab: true,
        customMarkup: true,
    });
    static readonly CDS_INTERVENTION_EDIT = new SlidePanelDefinition({
        key: 'CDS_INTERVENTION_EDIT',
        // path: 'admin/cds-interventions/:id/edit',
        permissions: ['PERMISSION_WRITE_CDS'],
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/admin/cds-interventions/cds-intervention/cds-intervention-edit/cds-intervention-edit.component').then(m => m.CdsInterventionEditComponent),
        loadChildren: () => import('../portal/admin/cds-interventions/cds-intervention/cds-intervention-edit/cds-intervention-edit.module').then(m => m.CdsInterventionEditModule),
    });
    static readonly CDS_INTERVENTION_CONDITION_EDIT = new SlidePanelDefinition({
        key: 'CDS_INTERVENTION_CONDITION_EDIT',
        permissions: ['PERMISSION_WRITE_CDS'],
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/admin/cds-interventions/cds-intervention-condition/cds-intervention-condition-edit/cds-intervention-condition-edit.component').then(m => m.CdsInterventionConditionEditComponent),
        loadChildren: () => import('../portal/admin/cds-interventions/cds-intervention-condition/cds-intervention-condition-edit/cds-intervention-condition-edit.module').then(m => m.CdsInterventionConditionEditModule),
    });
    static readonly REPRESENTATIVE_LIST = new SlidePanelDefinition({
        key: 'REPRESENTATIVE_LIST',
        permissions: ['PERMISSION_CREATE_USER_FROM_PATIENT'],
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/representatives/representatives-list/representatives-list.component').then(m => m.RepresentativesListComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/representatives/representatives-list/representative-list.module').then(m => m.RepresentativeListModule),
    });
    static readonly REPRESENTATIVE_EDIT = new SlidePanelDefinition({
        key: 'REPRESENTATIVE_EDIT',
        permissions: ['PERMISSION_CREATE_USER_FROM_PATIENT'],
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/representatives/representatives-edit/representatives-edit.component').then(m => m.RepresentativesEditComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-details/representatives/representatives-edit/representatives-edit.module').then(m => m.RepresentativesEditModule),
    });
    static readonly CDA_RECONCILE = new SlidePanelDefinition({
        key: 'CDA_RECONCILE',
        path: 'patient/:patientId/cda-reconcile/:id',
        size: 'lg',
        verticalAlign: 'top',
        defRootUrl: 'patients',
        loadComponent: () => import('../portal/@slide-panel-preview/cda-reconcile/cda-reconcile.component').then(m => m.CdaReconcileComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/cda-reconcile/cda-reconcile.module').then(m => m.CdaReconcileModule),
    });
    static readonly FAX_ACTIVATION = new SlidePanelDefinition({
        key: 'FAX_ACTIVATION',
        size: 'md',
        verticalAlign: 'center',
        loadComponent: () => import('../portal/@slide-panel-preview/fax-token-activation/fax-token-activation.component').then(m => m.FaxTokenActivationComponent),
    });
    static readonly ORGANIZATION_EDIT = new SlidePanelDefinition(ContentDefinitions.ORGANIZATION_EDIT, {
        size: 'md',
        verticalAlign: 'top',
        defRootUrl: 'admin/organizations',
        customMarkup: true,
    });
    static readonly FACILITY_EDIT = new SlidePanelDefinition(ContentDefinitions.FACILITY_EDIT, {
        size: 'lg',
        verticalAlign: 'top',
        defRootUrl: 'admin/organizations',
        customMarkup: true,
    });
    static readonly HL7_LAB = new SlidePanelDefinition({
        key: 'HL7_LAB',
        size: 'lg',
        verticalAlign: 'top',
        loadComponent: () => import('../portal/system-maintenance/lab-dashboard/hl7-lab/hl7-lab.component').then(m => m.Hl7LabComponent),
        customMarkup: true,
    });
    static readonly PATIENT_DOCUMENT = new SlidePanelDefinition({
        key: 'PATIENT_DOCUMENT',
        size: 'md',
        verticalAlign: 'top',
        loadComponent: () => import('../portal/@slide-panel-preview/patient-details/patient-document-uploader/patient-document-uploader.component').then(m => m.PatientDocumentUploaderComponent),
    });
    static readonly VISIT_BILLING_DAY = new SlidePanelDefinition({
        key: 'VISIT_BILLING_DAY',
        path: 'billing/visit-days/:date/:userId',
        pathStringParams: ['date'],
        defRootUrl: 'billing/visit-billing',
        size: 'lg',
        verticalAlign: 'top',
        loadComponent: () => import('../portal/billing/visit-billing-per-day/visit-billing-day/visit-billing-day.component').then(m => m.VisitBillingDayComponent),
        loadChildren: () => import('../portal/billing/visit-billing-per-day/visit-billing-day/visit-billing-day.module').then(m => m.VisitBillingDayModule),
    });
    static readonly LABEL_EDIT = new SlidePanelDefinition(ContentDefinitions.LABEL_EDIT, {
        key: 'LABEL_EDIT',
        verticalAlign: 'top',
    });
    static readonly LABEL_EDIT_NEW = new SlidePanelDefinition(ContentDefinitions.LABEL_EDIT_NEW, {
        key: 'LABEL_EDIT_NEW',
        verticalAlign: 'top',
        // defRootUrl: 'admin/labels-manager',
    });
    static readonly PSYCHOTROPICS_DETAIL = new SlidePanelDefinition({
        key: 'PSYCHOTROPICS_DETAIL',
        size: 'lg',
        verticalAlign: 'top',
        customMarkup: true,
        loadComponent: () => import('../portal/care-surveillance/psychotropics/psychotropics-detail/psychotropics-detail.component').then(m => m.PsychotropicsDetailComponent),
        loadChildren: () => import('../portal/care-surveillance/psychotropics/psychotropics-detail/psychotropics-detail.module').then(m => m.PsychotropicsDetailModule),
    });
    static readonly ADVANCED_MONITORING_PATIENTS = new SlidePanelDefinition({
        key: 'ADVANCED_MONITORING_PATIENTS',
        size: 'lg',
        customMarkup: true,
        loadComponent: () => import('../portal/@slide-panel-preview/advanced-monitoring-patients/advanced-monitoring-patients.component').then(m => m.AdvancedMonitoringPatientsComponent),
    });
    static readonly ACUTE_COMPLEX_PATIENTS = new SlidePanelDefinition({
        key: 'ACUTE_COMPLEX_PATIENTS',
        loadComponent: () => import('../portal/dashboard/clinical-oversight/complex-acute-patients/complex-acute-patients.component').then(m => m.ComplexAcutePatientsComponent),
        verticalAlign: 'top',
        customMarkup: true,
        size: 'md',
    });
    static readonly ACUTE_COMPLEX_PATIENT_DETAILS = new SlidePanelDefinition({
        key: 'ACUTE_COMPLEX_PATIENT_DETAIL',
        loadComponent: () => import('../portal/dashboard/clinical-oversight/complex-acute-patients/complex-acute-patient-detail/complex-acute-patient-detail.component').then(m => m.ComplexAcutePatientDetailComponent),
        verticalAlign: 'top',
        size: 'md',
    });
    static readonly CLINICAL_NOTE = new SlidePanelDefinition(ContentDefinitions.CLINICAL_NOTE, {
        key: 'CLINICAL_NOTE',
        verticalAlign: 'top',
        customMarkup: true,
        defRootUrl: 'patients',
    });
    static readonly CUSTOMER_EDIT = new SlidePanelDefinition({
        key: 'CUSTOMER_EDIT',
        size: 'md',
        customMarkup: true,
        path: 'admin/customers/:id/edit',
        defRootUrl: 'admin/customers',
        loadComponent: () => import('../portal/admin/customers/customer-edit/customer-edit.component').then(m => m.CustomerEditComponent),
        loadChildren: () => import('../portal/admin/customers/customer-edit/customer-edit.module').then(m => m.CustomerEditModule),
    });
    static readonly CUSTOMER_EDIT_NEW = new SlidePanelDefinition({
        ...this.CUSTOMER_EDIT,
        key: 'CUSTOMER_EDIT_NEW',
        path: 'admin/customers/new',
    });
}

export const SLIDE_PANEL_ROUTES = Object.values(SlidePanelDefinitions)
    .filter(x => x.path && (x.component || x.loadChildren))
    .map((x: SlidePanelDefinition) => x.getRoute());

export class SmartLinkDefinitions {
    static readonly PATIENT = new SmartLinkDefinition(ContentDefinitions.PATIENT, {
        getLabel: x => Human.getName(x),
        getPost: x => Patient.isDeceased(x) ? '†' : null,
        getLink: x => ContentDefinitions.PATIENT.getPath((getId(x))),
        loadComponent: () => import('../portal/@slide-panel-preview/patient-preview/patient-preview.component').then(m => m.PatientPreviewComponent),
        loadChildren: () => import('../portal/@slide-panel-preview/patient-preview/patient-preview.module').then(m => m.PatientPreviewModule),
    });
    static readonly LAB_TEST = new SmartLinkDefinition<LabTest>(ContentDefinitions.LAB_TEST, {
        getColorClass: x => LabTest.getColorClass(x),
        getDate: x => x.lab_report && x.lab_report.resulted_date,
        getLabel: x => x.name,
        getPost: x => x.lab_report ? `#${x.lab_report.order_id}` : '',
    });
    static readonly VITAL = new SmartLinkDefinition<CriticalVital>(ContentDefinitions.VITAL, {
        getColorClass: () => 'danger',
        getDate: x => x.recorded,
        getLabel: x => x.type?.name,
    });
    static readonly EVENT = new SmartLinkDefinition<PatientEvent>(ContentDefinitions.EVENT, {
        getColorClass: () => 'warning',
        getDate: x => x.date,
        getLabel: x => x.type[0] + x.type.slice(1).toLowerCase(),
    });
    static readonly MEDICATION = new SmartLinkDefinition<TreatmentEntry>(ContentDefinitions.MEDICATION, {
        getDate: x => x.start_date,
        getLabel: x => limitText(x.drugText, 40),
    });
    static readonly ICD_CODE = new SmartLinkDefinition<ICDCode>(ContentDefinitions.ICD_CODE, {
        getColor: x => ICDCodeGroupService.getIcdCodeColor(x),
        getPre: x => x.code,
        getLabel: x => limitText(x.description, 40),
    });
    static readonly SNOMED_CONCEPT = new SmartLinkDefinition<SnomedConcept>(ContentDefinitions.SNOMED_CONCEPT, {
        getLabel: x => limitText(x.term, 40),
    });
    static readonly DIAGNOSIS = new SmartLinkDefinition<Diagnosis>(ContentDefinitions.DIAGNOSIS, {
        getLink: x => x.icd && ContentDefinitions.PATIENT_ICD.getPath(getId(x.patient), getId(x.icd)),
        getDate: x => x.date_diagnosed,
        getColor: x => x.icd && SmartLinkDefinitions.ICD_CODE.getColor(x.icd),
        getPre: x => x.icd && SmartLinkDefinitions.ICD_CODE.getPre(x.icd),
        getLabel: x => limitText(x.diagnosis, 40),
    });
    static readonly PATIENT_ICD = new SmartLinkDefinition<Diagnosis>(ContentDefinitions.PATIENT_ICD, {
        getLink: x => ContentDefinitions.PATIENT_ICD.getPath(getId(x.patient), getId(x.icd)),
        getColor: x => SmartLinkDefinitions.ICD_CODE.getColor(x.icd),
        getPre: x => SmartLinkDefinitions.ICD_CODE.getPre(x.icd),
        getLabel: x => SmartLinkDefinitions.ICD_CODE.getLabel(x.icd),
        getTooltip: () => 'Open Dx details for Patient',
    });
    static readonly PATIENT_SNOMED = new SmartLinkDefinition<Diagnosis>(ContentDefinitions.PATIENT_SNOMED, {
        getLink: x => ContentDefinitions.PATIENT_SNOMED.getPath(getId(x.patient), getId(x.snomed_concept)),
        getColor: x => SmartLinkDefinitions.SNOMED_CONCEPT.getColor(x.snomed_concept),
        getPre: x => SmartLinkDefinitions.SNOMED_CONCEPT.getPre(x.snomed_concept),
        getLabel: x => SmartLinkDefinitions.SNOMED_CONCEPT.getLabel(x.snomed_concept),
        getTooltip: () => 'Open Dx details for Patient',
    });
    static readonly TREATMENT_RESISTANCE = new SmartLinkDefinition({
        key: 'TREATMENT_RESISTANCE',
        icon: 'exclamation-triangle',
        getLink: x => ContentDefinitions.LAB_TEST.getPath(TreatmentEntry.getResistance(x).object_id),
        getIcon: x => x.end_date ? null : 'exclamation-triangle',
        getLabel: x => TreatmentEntry.drugText(x),
        getTooltip: x => TreatmentEntry.getResistance(x).description,
    });
    static readonly OTHER_ORDER = new SmartLinkDefinition(ContentDefinitions.OTHER_ORDER, {
        getLabel: x => x.order_type,
        getTooltip: x => x.description,
        getDate: x => x.start_date,
    });
    static readonly PROCEDURE = new SmartLinkDefinition<Procedure>(ContentDefinitions.PROCEDURE, {
        getLabel: x => x.code?.name,
        getDate: x => x.performed,
    });
    static readonly IMMUNIZATION = new SmartLinkDefinition<Immunization>(ContentDefinitions.IMMUNIZATION, {
        getLabel: x => x.name, // BST TODO: add name when properly implemented
        getDate: x => x.date,
    });
    static readonly IMAGING_RESULT = new SmartLinkDefinition<ImagingResult>(ContentDefinitions.IMAGING_RESULT, {
        getLabel: x => x.code_display,
        getDate: x => x.date,
    });
    static readonly CLINICAL_RESULT = new SmartLinkDefinition<ClinicalResult>(ContentDefinitions.CLINICAL_RESULT, {
        getLabel: x => x.code_display,
        getDate: x => x.date,
    });
    static readonly ALLERGY = new SmartLinkDefinition<Allergy>(ContentDefinitions.ALLERGY, {
        getLabel: x => x.allergyText,
        getDate: x => x.start_date,
    });
    static readonly CDS_INTERVENTION = new SmartLinkDefinition(ContentDefinitions.CDS_INTERVENTION, {
        getLabel: x => x.title,
        loadComponent: () => import('../portal/admin/cds-interventions/cds-intervention/cds-intervention-preview/cds-intervention-preview.component').then(m => m.CdsInterventionPreviewComponent),
        loadChildren: () => import('../portal/admin/cds-interventions/cds-intervention/cds-intervention-preview/cds-intervention-preview.module').then(m => m.CdsInterventionPreviewModule),
    });
    static readonly CLINICAL_NOTE = new SmartLinkDefinition<ProgressNote>(ContentDefinitions.CLINICAL_NOTE, {
        getLabel: x => `${x.modified_by} - ${x.category}`,
        getDate: x => x.date,
    });
}

export class ReviewDefinitions {
    static readonly LAB_TEST = new ReviewDefinition<LabTest>(ContentDefinitions.LAB_TEST, {
        reviewFieldKey: 'lab_test_review',
        taskTypeKey: 'LAB_REVIEW',
    });
    static readonly LAB_REPORT = new ReviewDefinition<LabReport>(ContentDefinitions.LAB_REPORT, {
        reviewFieldKey: 'lab_test_review',
        taskTypeKey: 'LAB_REVIEW',
    });
    static readonly EVENT = new ReviewDefinition(ContentDefinitions.EVENT, {
        reviewFieldKey: 'event_review',
        taskTypeKey: 'GENERAL_REVIEW',
    });
    static readonly VITAL = new ReviewDefinition(ContentDefinitions.VITAL, {
        reviewFieldKey: 'vital_review',
        taskTypeKey: 'CRITICAL_VITALS',
    });
    static readonly MEDICATION = new ReviewDefinition(ContentDefinitions.MEDICATION, {
        reviewFieldKey: 'treatment_review',
        taskTypeKey: 'RX_RECONCILE',
    });
    static readonly OTHER_ORDER = new ReviewDefinition(ContentDefinitions.OTHER_ORDER, {
        reviewFieldKey: 'order_review',
        taskTypeKey: 'GENERAL_REVIEW',
    });
    static readonly MEDICATION_INTERACTION = new ReviewDefinition(ContentDefinitions.MEDICATION_INTERACTION, {
        taskTypeKey: 'RX_RECONCILE',
        actionEntryKey: 'treatment_history',
        reviewFieldKey: 'treatment_review',
    });
    static readonly PROCEDURE = new ReviewDefinition(ContentDefinitions.PROCEDURE, {
        taskTypeKey: 'PROCEDURE',
        reviewFieldKey: 'procedure_review',
    });
    static readonly IMMUNIZATION = new ReviewDefinition(ContentDefinitions.IMMUNIZATION, {
        taskTypeKey: 'IMMUNIZATION',
        reviewFieldKey: 'immunization_review',
    });
    static readonly IMAGING_RESULT = new ReviewDefinition(ContentDefinitions.IMAGING_RESULT, {
        taskTypeKey: 'IMAGING_RESULT',
        reviewFieldKey: 'imaging_result_review',
    });
    static readonly CLINICAL_RESULT = new ReviewDefinition(ContentDefinitions.CLINICAL_RESULT, {
        taskTypeKey: 'CLINICAL_RESULT',
        reviewFieldKey: 'clinical_test_result_review',
    });
    static readonly ALLERGY = new ReviewDefinition(ContentDefinitions.ALLERGY, {
        taskTypeKey: 'ALLERGY',
        reviewFieldKey: 'patient_allergy_review',
    });
}

export type ContentDefinitionKey = Exclude<keyof typeof ContentDefinitions, 'prototype'>;
export type SlidePanelTypeKey = Exclude<keyof typeof SlidePanelDefinitions, 'prototype'>;
export type SmartLinkTypeKey = Exclude<keyof typeof SmartLinkDefinitions, 'prototype'>;
export type ReviewTypeKey = Exclude<keyof typeof ReviewDefinitions, 'prototype'>;

export const FAC_MENU_ITEMS: MenuItemDefinition[] = [
    {
        name: "What's New",
        keywords: ['residents', 'overview', 'labs', 'oversight', 'medical'],
        link: '/whats-new',
        icon: 'newspaper',
        permissions: ['PERMISSION_READ_CLINICAL_OVERSIGHT', 'PERMISSION_READ_CENSUS_OVERSIGHT'],
    },
    {
        name: 'Arsana Views',
        keywords: ['residents', 'overview', 'census', 'admission', 'rehospitalization', 'oversight', 'medical'],
        link: '/arsana-views',
        icon: 'book-open',
        permissions: ['PERMISSION_READ_ADMIN_DASHBOARD'],
    },
    {
        name: 'Patients',
        keywords: ['medical records', 'resident', 'residents'],
        link: '/patients',
        icon: ContentDefinitions.PATIENT.icon,
        permissions: ['PERMISSION_READ_PATIENT_LIST'],
        testId: 'sidebar-patients',
    },
    {
        name: 'User Info',
        link: '/user-information',
        icon: SlidePanelDefinitions.USER_EDIT.icon,
        roles: ['ROLE_PATIENT_REPRESENTATIVE'],
    },
    {
        name: 'Dashboard',
        link: '/patient-dashboard',
        icon: ContentDefinitions.PATIENT.icon,
        roles: ['ROLE_PATIENT'],
    },
    {
        name: 'Facility Activity',
        icon: 'building',
        children: [
            {
                name: 'Facility Index',
                keywords: ['census', 'map', 'census map', 'admission tool', 'residents', 'beds'],
                icon: 'bed',
                link: '/facility-activity/facility-index',
                permissions: ['PERMISSION_READ_CENSUS_MAP'],
            },
            {
                name: 'Census Trends',
                keywords: ['census', 'residents', 'trends', 'beds', 'executive oversight'],
                icon: 'chart-bar',
                link: '/facility-activity/census-trends',
                permissions: ['PERMISSION_READ_CENSUS_TRENDS'],
            },
            {
                name: 'Rehospitalization',
                keywords: ['census', 'residents', 'trends', 'hospital leaves', 'readmissions', 'emergency department visit', 'inpatient stays', 'in-patient stays', 'hospital transfers'],
                icon: 'ambulance',
                link: '/facility-activity/rehospitalization',
                permissions: ['PERMISSION_READ_CENSUS_TRENDS', 'PERMISSION_READ_REHOSPITALIZATION'],
            },
        ],
    },
    {
        name: 'Care Surveillance',
        icon: 'stethoscope',
        children: [
            {
                name: 'Infection Management',
                keywords: ['infections', 'map', 'infection map'],
                icon: 'map',
                link: '/care-surveillance/infection-management',
                permissions: ['PERMISSION_READ_INFECTION_MAP'],
            },
            {
                name: 'COVID Map',
                keywords: ['covid', 'map', 'infection map'],
                icon: 'disease',
                link: '/care-surveillance/covid-map',
                permissions: ['PERMISSION_READ_COVID_MAP'],
            },
            {
                name: 'Lab Reports',
                link: '/care-surveillance/lab-reports',
                icon: ContentDefinitions.LAB_REPORT.icon,
                permissions: ['PERMISSION_READ_LAB_REPORT_LIST'],
            },
            {
                name: 'Culture Specimen Dashboard',
                link: '/care-surveillance/culture-specimen-dashboard',
                icon: SlidePanelDefinitions.ORGANISM.icon,
                permissions: ['PERMISSION_READ_LAB_INFECTION_CONTROL'],
            },
            {
                name: 'Medications',
                link: '/care-surveillance/medications',
                icon: ContentDefinitions.MEDICATION.icon,
                permissions: ['PERMISSION_READ_MEDICATION_LIST'],
            },
            {
                name: 'Antibiotics',
                keywords: ['trends', 'drugs'],
                icon: 'pills',
                link: '/care-surveillance/antibiotics',
                permissions: ['PERMISSION_READ_ANTIBIOTICS'],
            },
            {
                name: 'Psychotropics',
                keywords: ['trends', 'drugs', 'psychotropics'],
                icon: 'prescription-bottle',
                link: '/care-surveillance/psychotropics',
                permissions: ['PERMISSION_READ_ANTIPSYCHOTICS'],
            },
            {
                name: 'Immunizations',
                keywords: ['covid', 'immunizations', 'infection'],
                icon: 'syringe',
                link: '/care-surveillance/immunizations',
                permissions: ['PERMISSION_READ_IMMUNIZATIONS'],
            },
            {
                name: 'Infection Trends',
                icon: 'chart-line',
                link: '/care-surveillance/trends',
                permissions: ['PERMISSION_EXPERIMENTAL_FEATURES'],
                andPermissions: ['PERMISSION_READ_TRENDS'],
            },
            {
                name: 'EMR Verification',
                keywords: ['administration', 'matrix', 'medical records', 'coaching', 'infection admin tips'],
                icon: 'edit',
                link: '/care-surveillance/emr-verification',
                permissions: ['PERMISSION_READ_COACHING_INFECTION_ADMINISTRATION'],
            },
            {
                name: 'CQM Calculator',
                keywords: ['cqm', 'calculator', 'quality', 'measures', 'quality measures'],
                icon: 'notes-medical',
                link: '/care-surveillance/cqm-calculator',
                permissions: ['PERMISSION_GENERATE_QRDA'],
            },
            {
                name: 'PI Measures',
                keywords: ['pi', 'measures', 'interoperability', 'promoting'],
                icon: 'exchange',
                link: '/care-surveillance/pi-measures',
                permissions: ['PERMISSION_READ_PI_MEASURES'],
            },
        ],
    },
    {
        name: 'Documents',
        icon: 'folder-open',
        children: [
            {
                name: 'Report Catalog',
                keywords: ['reports', 'download', 'interact', 'infections', 'census', 'print'],
                link: '/documents/reports',
                icon: 'print',
                permissions: ['PERMISSION_READ_FACILITY', 'PERMISSION_READ_CENSUS_TRENDS'],
            },
            {
                name: 'Document Storage',
                keywords: ['uploaded reports', 'download'],
                link: '/documents/storage',
                icon: 'hdd',
                permissions: ['PERMISSION_READ_UPLOADED_REPORTS', 'PERMISSION_WRITE_UPLOADED_REPORTS'],
            },
        ],
    },
    // {
    //     name: 'Coaching',
    //     icon: 'whistle',
    //     children: [
    //     ]
    // },
    {
        name: 'Admin',
        icon: 'pencil-alt',
        children: [
            {
                name: 'Users',
                link: '/admin/users',
                icon: 'user',
                permissions: ['PERMISSION_WRITE_USER', 'PERMISSION_READ_USER_LIST'],
            },
            {
                name: 'Billing Info Requests',
                link: '/admin/billing-info-requests',
                keywords: ['billing info', 'billing info requests', 'billing'],
                icon: 'address-book',
                permissions: ['PERMISSION_APPROVE_BILLING_INFO_REQUESTS'],
            },
            {
                name: 'Physician Teams',
                link: '/admin/physician-teams',
                icon: 'user-md',
                permissions: ['PERMISSION_READ_PHYSICIAN_TEAMS'],
            },
            {
                name: 'Locations',
                link: '/admin/locations',
                icon: 'map-marker',
                keywords: ['map', 'edit beds'],
                permissions: ['PERMISSION_READ_LOCATIONS', 'PERMISSION_WRITE_LOCATIONS'],
            },
            {
                name: 'Nursing Stations',
                link: '/admin/nursing-stations',
                icon: 'fax',
                keywords: ['fax', 'faxing', 'nurse'],
                permissions: ['PERMISSION_WRITE_ORGANIZATION'],
            },
            {
                name: 'Patient Information Audit',
                link: '/admin/patient-information-audit',
                keywords: ['patients', 'audit'],
                icon: ContentDefinitions.PATIENT.icon,
                permissions: ['PERMISSION_READ_PATIENT_INFORMATION_AUDIT'],
            },
            {
                name: 'Patient Access Management',
                link: '/admin/patient-access-management',
                keywords: ['patients', 'access'],
                icon: 'edit',
                permissions: ['PERMISSION_DECIDE_ACCESS_PERMISSIONS', 'PERMISSION_REQUEST_ACCESS_PERMISSIONS'],
            },
            {
                name: 'Initial Import',
                link: '/admin/initial-import/list',
                keywords: ['import', 'initial'],
                icon: 'play',
                permissions: ['PERMISSION_READ_INITIAL_IMPORT'],
            },
        ],
    },
];

export const OTHER_MENU_ITEMS: MenuItemDefinition[] = [
    {
        name: 'Notes',
        icon: ContentDefinitions.TASK.icon,
        children: [
            {
                name: 'Personal',
                keywords: ['follow issues', 'following', 'ccm tasks', 'ccm notes', 'progress notes'],
                link: '/arsana-notes',
                icon: 'user',
                permissions: ['PERMISSION_FOLLOW_PATIENTS'],
                exactMatch: true,
            },
            {
                name: 'Facility Inbox',
                keywords: ['follow issues', 'following', 'ccm tasks', 'ccm notes', 'actions', 'new orders', 'progress notes'],
                link: '/arsana-notes/facility-inbox',
                icon: 'inbox',
                permissions: ['PERMISSION_READ_FACILITY_TASKS'],
            },
            {
                name: 'Team Notes',
                keywords: ['follow issues', 'following', 'ccm tasks', 'ccm notes', 'actions', 'new orders', 'progress notes'],
                link: '/arsana-notes/team-notes',
                icon: 'users',
                permissions: ['PERMISSION_READ_TEAM_NOTES'],
            },
            {
                name: 'Orders',
                keywords: ['follow issues', 'following', 'ccm tasks', 'ccm notes', 'actions', 'new orders', 'progress notes', 'broadstreet orders', 'arsana orders', 'visits', 'rx orders', 'lab orders', 'other orders', 'e-prescribing'],
                link: '/arsana-notes/orders',
                icon: 'edit',
                permissions: ['PERMISSION_READ_FACILITY_TASKS', 'PERMISSION_READ_TEAM_NOTES'],
            },
            {
                name: 'Rx Worklist',
                keywords: ['rx orders', 'e-scribing', 'e-prescriptions', 'e-prescribing', 'work list'],
                link: '/arsana-notes/rx-worklist',
                icon: 'list',
                permissions: ['PERMISSION_READ_E_PRESCRIBING_WORKLIST'],
            },
        ],
    },
    {
        name: 'Lab Worklist',
        keywords: ['lab orders', 'lab worklist', 'lab work list'],
        link: '/lab-testing/worklist',
        icon: 'vials',
        permissions: ['PERMISSION_READ_LAB_WORKLIST'],
    },
    {
        name: 'Part B Worklist',
        keywords: ['orders', 'orders download'],
        link: '/order-download',
        icon: 'print',
        permissions: ['PERMISSION_READ_ORDERS_REPORT'],
    },
    {
        name: 'Applications',
        keywords: ['applications'],
        link: '/applications',
        icon: 'cogs',
        permissions: ['PERMISSION_READ_APPLICATIONS'],
    },
    {
        name: 'COVID Testing',
        keywords: ['labs', 'shipment', 'coronavirus', 'tests', 'covid-19', 'boxes', 'bags', 'vials'],
        link: '/covid-testing',
        icon: 'vials',
        permissions: ['PERMISSION_READ_COVID_TESTING'],
    },
    {
        name: 'Scheduler',
        icon: 'calendar',
        children: [
            {
                name: 'Calendar',
                keywords: ['visits', 'in-patient', 'list', 'calendar', 'visit planner'],
                link: '/scheduler',
                exactMatch: true,
                // queryParams: {onCall: false},
                icon: 'calendar',
                permissions: ['PERMISSION_READ_VISITS'],
            },
            {
                name: 'Next Visit Scheduler',
                keywords: ['visits', 'in-patient', 'list', 'calendar', 'visit planner'],
                link: '/scheduler/next-visits',
                icon: 'history',
                permissions: ['PERMISSION_READ_NEXT_VISIT_SCHEDULER'],
            },
            {
                name: 'Scheduler Worklist',
                keywords: ['visits', 'in-patient', 'list', 'calendar', 'visit planner', 'patient worklists'],
                link: '/scheduler/scheduler-worklist',
                icon: 'user-md',
                permissions: ['PERMISSION_READ_SCHEDULER_WORKLIST'],
            },
        ],
    },
    {
        name: 'Billing',
        icon: 'receipt',
        children: [
            {
                name: 'Overview',
                keywords: ['cms billing', 'ccm overview', 'ccm billing', 'task', 'reporting', 'productivity'],
                link: '/billing/overview',
                icon: 'stopwatch',
                permissions: ['PERMISSION_READ_PRODUCTIVITY_REPORT', 'PERMISSION_READ_CCM_BILLING'],
            },
            {
                name: 'VCM Monthly Billing',
                keywords: ['task', 'reporting', 'summary'],
                link: '/billing/vcm-monthly-billing',
                icon: 'tasks',
                permissions: ['PERMISSION_READ_CCM_BILLING', 'PERMISSION_READ_VCM_BILLING_AUDIT'],
            },
            {
                name: 'Visit Productivity',
                keywords: ['task', 'reporting', 'summary'],
                link: '/billing/visit-productivity',
                icon: 'history',
                permissions: ['PERMISSION_READ_VISIT_BILLING_AUDIT'],
            },
            {
                name: 'Visit Billing',
                keywords: ['task', 'reporting', 'summary'],
                link: '/billing/visit-billing',
                icon: 'tasks',
                permissions: ['PERMISSION_READ_VISIT_BILLING'],
            },
            {
                name: 'Lab Billing',
                keywords: ['lab', 'billing', 'summary'],
                link: '/billing/lab-billing',
                icon: 'tasks',
                permissions: ['PERMISSION_READ_LAB_BILLING'],
            },
            {
                name: "Patients' Billing Info",
                keywords: ['patients', 'billing', 'ccm'],
                link: '/billing/patients-billing-info',
                icon: 'address-book',
                permissions: ['PERMISSION_SEND_BILLING_INFO_REQUESTS'],
            },
            {
                name: 'Task History',
                keywords: ['tasks', 'billing', 'ccm', 'notes'],
                link: '/billing/task-history',
                icon: 'history',
                permissions: ['PERMISSION_READ_TASK_HISTORY'],
            },
        ],
    },
    {
        name: 'Document Inbox',
        keywords: ['clinical', 'documents', 'document', 'assign', 'ccd', 'ccda', 'c-cda', 'inbox'],
        link: '/documents/clinical-documents',
        icon: 'file-alt',
        permissions: ['PERMISSION_READ_CLINICAL_DOCUMENTS', 'PERMISSION_WRITE_CLINICAL_DOCUMENTS'],
    },
    {
        name: 'System Settings',
        icon: 'cogs',
        children: [
            {
                name: 'Organizations',
                keywords: ['facilities', 'facility edit'],
                link: '/admin/organizations',
                icon: 'building',
                permissions: ['PERMISSION_WRITE_ORGANIZATION'],
            },
            {
                name: 'Groups',
                keywords: ['edit teams'],
                link: '/admin/groups',
                icon: 'users',
                permissions: ['PERMISSION_WRITE_GROUPS'],
            },
            {
                name: 'Customers',
                keywords: ['customers', 'customer edit', 'edit teams'],
                link: '/admin/customers',
                icon: 'briefcase',
                permissions: ['PERMISSION_READ_CUSTOMERS', 'PERMISSION_WRITE_CUSTOMERS'],
            },
            {
                name: 'Facility Proposals',
                keywords: ['organizations', 'facility edit', 'add facilities'],
                link: '/admin/facility-proposals',
                icon: 'building',
                permissions: ['PERMISSION_READ_FACILITY_PROPOSALS'],
            },
            {
                name: 'User Proposals',
                keywords: ['edit user', 'create user', 'add users'],
                link: '/admin/user-proposals',
                icon: 'user',
                permissions: ['PERMISSION_READ_USER_PROPOSALS'],
            },
            {
                name: 'Patient Proposals',
                keywords: ['patient', 'patient edit', 'add patient', 'create patient'],
                link: '/admin/patient-proposals',
                icon: 'hospital-user',
                permissions: ['PERMISSION_READ_PATIENT_PROPOSALS'],
            },
            {
                name: 'Import Accounts',
                link: '/admin/import-accounts',
                icon: 'key',
                permissions: ['PERMISSION_READ_IMPORT_ACCOUNTS_LIST'],
            },
            {
                name: 'Import',
                link: '/import',
                icon: 'database',
                permissions: ['PERMISSION_READ_IMPORT'],
            },
            {
                name: 'Auth Logs',
                link: '/admin/auth-logs',
                icon: 'users',
                permissions: ['PERMISSION_READ_LOGS'],
            },
            {
                name: 'Billing Codes',
                keywords: ['ccm', 'mac localities', 'tasks', 'prices'],
                link: '/admin/billing-codes',
                icon: 'receipt',
                permissions: ['PERMISSION_READ_BILLING_CODES'],
            },
            {
                name: 'Drug Categories',
                link: '/admin/drug-categories',
                keywords: ['drugs', 'categorization'],
                icon: 'pills',
                permissions: ['PERMISSION_WRITE_DRUG_CATEGORIES'],
            },
            {
                name: 'Diagnosis Categories',
                link: '/admin/diagnosis-categories',
                keywords: ['diagnoses', 'icd codes', 'icd grouping', 'dx', 'categorization'],
                icon: 'diagnoses',
                permissions: ['PERMISSION_WRITE_DIAGNOSIS_CATEGORIES'],
            },
            {
                name: 'Psychotropic Group Settings',
                link: '/admin/psychotropic-group-settings',
                keywords: ['psychotropic', 'antipsychotic', 'group'],
                icon: 'prescription-bottle',
                permissions: ['PERMISSION_WRITE_PSYCHOTROPIC_GROUPS'],
            },
            {
                name: 'CCM Task Categories',
                link: '/admin/ccm-task-categories',
                keywords: [],
                icon: 'stopwatch',
                permissions: ['PERMISSION_WRITE_TASK_TYPES'],
            },
            {
                name: 'System Messages',
                link: '/admin/system-alerts',
                keywords: ['system alerts', 'system messages', 'system', 'alerts', 'alert', 'messages', 'message'],
                icon: 'megaphone',
                permissions: ['PERMISSION_WRITE_SYSTEM_ALERTS'],
            },
            {
                name: 'App Management',
                link: '/admin/app-management',
                keywords: ['app'],
                icon: 'receipt',
                permissions: ['PERMISSION_WRITE_APPLICATIONS'], //PERMISSIONS_READ_APPLICATIONS
            },
            {
                name: 'CDS Interventions',
                link: '/admin/cds-interventions',
                keywords: ['cds', 'intervention', 'clinical decision support'],
                icon: 'comment-exclamation',
                permissions: ['PERMISSION_WRITE_CDS'],
            },
            {
                name: 'Labels',
                link: '/admin/labels-manager',
                icon: 'tags',
                permissions: ['PERMISSION_WRITE_LABELS'],
            },
            {
                name: 'Level of Care',
                link: '/admin/level-of-care',
                icon: 'file-invoice-dollar',
                permissions: ['PERMISSION_WRITE_LEVEL_OF_CARE_PAYERS'],
                keywords: ['payers', 'insurance', 'insurance companies', 'insurance payers', 'insurance payor', 'insurance payors', 'insurance payer'],
            },
        ],
    },
    {
        name: 'System Maintenance',
        icon: 'toolbox',
        children: [
            {
                name: 'HL7 Dashboard',
                link: '/system-maintenance/hl7',
                queryParams: {tab: 'dashboard'},
                icon: 'info-circle',
                permissions: ['PERMISSION_READ_HL7_MESSAGES', 'PERMISSION_WRITE_HL7_MESSAGES'],
            },
            {
                name: 'Lab Dashboard',
                link: '/system-maintenance/lab-dashboard',
                icon: 'vials',
                permissions: ['PERMISSION_READ_HL7_MESSAGES', 'PERMISSION_WRITE_HL7_MESSAGES'],
            },
            {
                name: 'Physician Team Management',
                link: '/system-maintenance/physician-team-management',
                icon: 'user-md',
                permissions: ['PERMISSION_READ_PHYSICIAN_TEAMS'],
                keywords: ['physician teams', 'care teams', 'physicians'],
            },
            {
                name: 'EMR Uploads',
                link: '/system-maintenance/emr-uploads',
                icon: 'file-upload',
                permissions: ['PERMISSION_READ_EMR_UPLOADS', 'PERMISSION_WRITE_EMR_UPLOADS'],
            },
            {
                name: 'Person Management',
                link: '/admin/person-management',
                keywords: ['persons', 'people', 'patients'],
                icon: 'check',
                permissions: ['PERMISSION_CONFIRM_PERSON_PROPOSAL', 'PERMISSION_READ_PERSONS'],
            },
            {
                name: 'Merge Patients',
                link: '/system-maintenance/patients-merge',
                icon: 'code-merge',
                permissions: ['PERMISSION_MERGE_PATIENTS'],
            },
            {
                name: 'Import Errors',
                link: '/system-maintenance/import-errors',
                icon: 'fax',
                permissions: ['PERMISSION_READ_IMPORT_ERRORS'],
            },
            {
                name: 'Census Status Check',
                link: '/system-maintenance/census-status-check',
                icon: 'info',
                permissions: ['PERMISSION_UPDATE_CENSUS'],
            },
            {
                name: 'System Operations',
                link: '/system-maintenance/system-operations',
                icon: 'wrench',
                permissions: ['PERMISSION_READ_SYSTEM_OPERATIONS'],
            },
            {
                name: 'eRx Origins',
                link: '/system-maintenance/erx-origins',
                icon: 'list',
                keywords: ['erx', 'origins', 'erx origins'],
                permissions: ['PERMISSION_READ_ERX_ORIGINS'],
            },
        ],
    },
];

export const EXTRA_MENU_ITEMS = [
    {
        name: 'Import Schedule',
        keywords: ['data import', 'schedule'],
        link: '/import/schedule',
        icon: 'calendar',
        permissions: ['PERMISSION_READ_IMPORT'],
    },
    {
        name: 'Add New User',
        keywords: ['create user'],
        link: '/admin/users/new',
        icon: 'user-plus',
        permissions: ['PERMISSION_WRITE_USER'],
    },
    {
        name: 'EMR Upload Configurations',
        keywords: ['emr upload configurations'],
        link: '/system-maintenance/emr-uploads-configurations',
        icon: 'info-circle',
        permissions: ['PERMISSION_READ_EMR_UPLOADS', 'PERMISSION_WRITE_EMR_UPLOADS'],
    },
    {
        name: 'HL7 Messages',
        keywords: ['hl7', 'messages'],
        link: '/system-maintenance/hl7',
        queryParams: {tab: 'messages'},
        icon: 'inbox',
        permissions: ['PERMISSION_READ_EMR_UPLOADS', 'PERMISSION_WRITE_EMR_UPLOADS'],
    },
];
